.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.ties{
  background-color: red;
}
#demo{
  font-size: 25px;
  font-weight: 600;
}
.popup-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
  bottom: 104px;
  right: 51px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
@media only screen and (max-width: 600px) {
  .float{
    position:fixed;
    width:60px;
    height:60px;
    bottom: 104px;
    right: 3px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
  }
}
.my-float{
	margin-top:16px;
}

@media (max-width: 768px) {
  .popup-content {
    max-width: 95%;
    width: auto;
  }

  .popup-content img {
    width: 100%;
    height: auto;
  }
}