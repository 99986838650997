body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lenk {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat";
  text-transform: capitalize;
  /* padding: 35px 0 30px; */
  transition: 0.5s;
  color: #000;
  margin-right: 10px;
}

.lenk_button {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Montserrat";
  border: 1px solid #373745;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  border-radius: 50px;
  transition: 0.5s;
  width: 110px;
  text-align: center;
}

.main-header {
  transition: background-color 0.3s ease;
}

.main-header.scrolled {
  background-color: #f8f9fa; /* Change this to your desired color */
  box-shadow: 0 4px 2px -2px gray; /* Optional: Add a shadow for better visibility */
  /* position: fixed; */
  width: 100%;
}

.bg-primary.bg-soft {
  background-color: rgba(85, 110, 230, 0.25) !important;
}

.form-horizontal {
  text-align: center;
}

.vertical-menu {
  transition: width 0.3s; /* Smooth transition for width change */
}

.vertical-menu.closed {
  width: 0; /* Sidebar hidden */
  overflow: hidden; /* Hide overflow when closed */
}

.vertical-menu.open {
  width: 250px; 
  overflow: auto;
  /* Adjust this width as needed */
}

.main-content {
  flex: 1;
  transition: margin-left 0.3s; /* Smooth transition for margin change */
}

.main-content.closed {
  margin-left: 0;
}

.main-content.open {
  margin-left: 250px; /* Same width as the sidebar */
}

.widthhhVal {
  width: 103%;
}

.vertical-menu .sub-menu {
  display: none;
  list-style: none;
  padding-left: 20px;
}

.vertical-menu .sub-menu.open {
  display: block;
}

.vertical-menu .sub-menu.closed {
  display: none;
}

.recycllee{
  margin-top: -12px;
}


@media screen and (max-width: 600px) {
  .dss {
    padding: 12px;
  }

  .widthhhVal {
    width: 106%;
    margin-left: 6px;
  }
}

@media (max-width: 868px) {
  .vertical-menu {
    width: 0; /* Sidebar hidden by default on smaller screens */
  }

  .main-content {
    margin-left: 0; /* No margin on smaller screens */
  }

  .resHdr{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lenk_buttonLogin{
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .page-content {
    padding: calc(40px + 24px) calc(0px * .75) 60px calc(0px * .75)
  }

  .partnrPdd{
    margin-top: 25px;
  }
}

@media (min-width:768px) and (max-width:1100px) {
  .avatar-sm{
    width: 1.5rem;
    height: 1.5rem;
    }

    .avatar-sm i{
    font-size: 15px !important;
  }

  .ksfkw{
    top: .7rem;
    left: -20px;
  }

  .ksfkwT{
    top: .7rem;
  }
}